var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"lineup-box p-l-30 p-r-30 p-b-15 p-relative"},[(_vm.competitionData)?[_c('div',{staticClass:"p-absolute transform-box"},[_c('div',{staticClass:"plat-lineup bg-no-repeat p-relative bg-size-cover bg-center"},[[_vm._l((_vm.homeMembers),function(members){return _vm._l((members),function(member){return _c('member',{key:member.id,attrs:{"is-home":"","member":member}})})})],[_vm._l((_vm.awayMembers),function(members){return _vm._l((members),function(member){return _c('member',{key:member.id,attrs:{"is-home":false,"member":member}})})})]],2)]),(_vm.competitionData)?_c('div',{staticClass:"battle-info m-t-20 w-100 flex justify-between"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"home flex align-center"},[_c('div',{staticClass:"icon border-radius-50 m-r-10 bg-center bg-no-repeat bg-size-100",style:({
                        backgroundImage: `url(${_vm.homeLogo})`
                    })}),_c('span',[_vm._v(_vm._s(_vm.competitionData.homeNameChs))])]),_c('div',{staticClass:"m-t-10 m-b-15 font-16 font-regular font-400"},[_vm._v(" 阵型: "+_vm._s(_vm.homeLineup)+" ")]),_c('div',{staticClass:"backup p-l-10 text-left font-18 font-medium text-333"},[_vm._v(" 替补名单 ")])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"away flex align-center"},[_c('span',[_vm._v(_vm._s(_vm.competitionData.awayNameChs))]),_c('div',{staticClass:"icon border-radius-50 m-l-10 bg-center bg-no-repeat bg-size-100",style:({
                        backgroundImage: `url(${_vm.awayLogo})`
                    })})]),_c('div',{staticClass:"m-t-10 m-b-15 font-16 font-regular font-400"},[_vm._v(" 阵型: "+_vm._s(_vm.awayLineup)+" ")]),_c('div',{staticClass:"backup p-r-10 text-right font-18 font-medium text-333"},[_vm._v(" 替补名单 ")])])]):_vm._e(),_c('div',{staticClass:"battle-backup m-t-10"},[_vm._l((_vm.backupMaxCount),function(n){return [_c('div',{key:`row-${n}`,staticClass:"row p-10 flex justify-between align-center"},[[(_vm.homeBackups[n-1])?_c('div',{staticClass:"left-member flex align-center"},[_c('span',{staticClass:"number"},[_vm._v(_vm._s(_vm.homeBackups[n-1].number))]),_c('div',{staticClass:"icon m-l-10 m-r-10 bg-center bg-no-repeat bg-size-cover",style:({
                            backgroundImage: `url(${_vm.homeBackups[n-1].photo})`
                        })}),_c('div',{staticClass:"member-info flex flex-column"},[_c('span',{staticClass:"font-14 name font-regular text-333 font-400"},[_vm._v(_vm._s(_vm.homeBackups[n-1].nameChs))]),_c('span',{staticClass:"font-12 font-regular font-400 text-999"},[_vm._v(_vm._s(_vm.homeBackups[n-1].positionCn))])])]):_c('div',{staticClass:"left-member flex align-center visibility-hidden"})],[(_vm.awayBackups[n-1])?_c('div',{staticClass:"right-member flex align-center"},[_c('div',{staticClass:"member-info flex flex-column text-right"},[_c('span',{staticClass:"font-14 name font-regular text-333 font-400"},[_vm._v(_vm._s(_vm.awayBackups[n-1].nameChs))]),_c('span',{staticClass:"font-12 font-regular font-400 text-999"},[_vm._v(_vm._s(_vm.awayBackups[n-1].positionCn))])]),_c('div',{staticClass:"icon m-l-10 m-r-10 bg-center bg-no-repeat bg-size-cover",style:({
                            backgroundImage: `url(${_vm.awayBackups[n-1].photo})`
                        })}),_c('span',{staticClass:"number"},[_vm._v(_vm._s(_vm.awayBackups[n-1].number))])]):_c('div',{staticClass:"left-member flex align-center visibility-hidden"})]],2)]})],2)]:_vm._e(),(!_vm.loading && !_vm.competitionData)?_c('no-data'):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }